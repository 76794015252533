import React from 'react'
import { css } from "@emotion/core";
import { useStaticQuery, graphql } from "gatsby";

import MarketoForm from "./MarketoForm";
import ResponsiveContainer from "./ResponsiveContainer";
import SmartLink from "./SmartLink";
import Paragraph from "./Paragraph";

import { ReactComponent as LogoSvgInline } from './Header/assets/servicelink-logo.svg';
import { BREAKPOINTS, COLORS, DEFAULT_FONT_SIZES_MAP } from "../css-vars";
import { createSpacingUnitMediaQueries, mediaQuery, pixelsToRem, responsiveFontSizes } from "../utils/css-helpers";
import { HiddenTextForScreenReader } from "../utils/a11y";
import { formatContentfulMenu } from "../utils/contentful";

import { homeUrl } from "../routes";

import { privacyOptOutUrl } from "./../pages/privacy-data-collection-opt-out-request";

const Footer = ({ showContactForm = true }) => {
  const { allContentfulGlobalSiteConfig } = useStaticQuery(graphql`
    {
      allContentfulGlobalSiteConfig(limit: 1, sort: {
        fields: createdAt,
        order: DESC
      }) {
        edges {
          node {
            footerLinks {
              ...MenuList
            }
          }
        }
      }
    }
  `);

  const links = formatContentfulMenu(allContentfulGlobalSiteConfig, `footer`);

  return (<footer>
    {showContactForm &&
      <ResponsiveContainer
        id="contact"
        width="thin"
        css={css`
          ${createSpacingUnitMediaQueries({'margin-top': `calc(SPACING_UNIT * 1.5)`})}
        `}
      >
        <MarketoForm
          formId="1003"
          heading="Contact Us"
          message="Can't find what you're looking for? Let us point you in the right direction."
          successHeading="Inquiry Sent"
          successMessage="Thank you for taking the time to fill out the form, somebody will be in contact with you shortly."
        />
      </ResponsiveContainer>
    }

    <div css={css`
      ${responsiveFontSizes(DEFAULT_FONT_SIZES_MAP.small[0], DEFAULT_FONT_SIZES_MAP.small[1])};

      background: ${COLORS.dark};
      color: ${COLORS.lightest};
      overflow: hidden;

      a:hover {
        text-decoration: underline;
      }
    `}>
      <ResponsiveContainer spacingBottom="full" spacingTop="full" css={css`
        ${mediaQuery({ minWidth: BREAKPOINTS.large })} {
          align-items: flex-start;
          display: flex;
          justify-content: space-between;
        }
      `}>
        <SmartLink
          link={homeUrl}
          css={css`
            display: flex;

            :focus {
              opacity: 0.5;
            }
          `}
        >
          <LogoSvgInline aria-labelledby="footer-logo-label" css={css`
            width: ${pixelsToRem(200 * 0.88)};
            height: ${pixelsToRem(50 * 0.88)};

            ${mediaQuery({ minWidth: BREAKPOINTS.large })} {
              margin-top: ${pixelsToRem(-20)}; // WHY: Vertically centers the logo
              height: ${pixelsToRem(50)};
              width: ${pixelsToRem(200)};
            }
          `}/>
          <HiddenTextForScreenReader id="footer-logo-label">ServiceLink</HiddenTextForScreenReader>
        </SmartLink>
        <nav css={css`
          margin-top: 4em;

          ${mediaQuery({ minWidth: BREAKPOINTS.large })} {
            margin-top: 0;
            width: 66.667%;
          }
        `}>
          <ul css={css`

            ${mediaQuery({ minWidth: BREAKPOINTS.medium })} {
              display: flex;
              justify-content: space-between;
            }
          `}>
            {links.map(link => (
              <li
                key={link.link}
                css={css`
                  display: block;
                  position: relative;

                ${mediaQuery({ minWidth: BREAKPOINTS.small, maxWidth: BREAKPOINTS.medium })} {
                  display: flex;
                }
              `}>
                <SmartLink link={link.link} css={css`
                  display: block;
                  text-transform: uppercase;
                  font-weight: 500;

                  ${mediaQuery({ maxWidth: BREAKPOINTS.medium })} {
                    margin-bottom: ${pixelsToRem(25)};
                  }

                  ${mediaQuery({ minWidth: BREAKPOINTS.small, maxWidth: BREAKPOINTS.medium })} {
                    display: flex;
                    width: 10em;
                    flex-shrink: 0;
                  }

                  :focus {
                    text-decoration: underline;
                  }
                `}>{link.label}</SmartLink>

                {link.subLinks && (
                  <ul css={css`

                    ${mediaQuery({ maxWidth: BREAKPOINTS.medium })} {
                      display: flex;
                      flex-wrap: wrap;
                    }

                    ${mediaQuery({ maxWidth: BREAKPOINTS.small })} {
                      margin-bottom: ${pixelsToRem(15)};
                      margin-top: ${pixelsToRem(-10)};
                    }
                  `}>
                    {link.subLinks.map(subLink => (
                      <li key={subLink.link} css={css`
                        margin: ${pixelsToRem(15)} 0;

                        ${mediaQuery({ maxWidth: BREAKPOINTS.medium })} {
                          margin: 0 ${pixelsToRem(20)} ${pixelsToRem(15)} 0;
                        }
                      `}>
                        <SmartLink link={subLink.link} css={css`
                          :focus {
                            text-decoration: underline;
                          }
                        `}>{subLink.label}</SmartLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </ResponsiveContainer>

      <ResponsiveContainer spacingTop="none" spacingBottom="half" css={css`
        ${responsiveFontSizes(DEFAULT_FONT_SIZES_MAP.small[0], DEFAULT_FONT_SIZES_MAP.small[1])};

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: baseline;
      `}>
        <Paragraph level="small" css={css`
          color: inherit;
        `}>
          TM ©{new Date().getFullYear()} ServiceLink IP Holding Company, LLC, and/or affiliate. All rights reserved.
        </Paragraph>
        <nav css={css`
          display: block;
          justify-self: right;
          width: 100%;
        `}>
          <ul css={css`
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            & > li {
              display: block;
              line-height: 1.35;
              margin-top: 0.5em;
              margin-right: 1.5em;
              text-transform: uppercase;
              white-space: nowrap;
            }
            & > li:last-of-type {
              margin-right: 0;
            }

            a:focus {
              text-decoration: underline;
            }
          `}>
            <li><SmartLink link="//assets.ctfassets.net/1bq4zli02do9/5agHlQhV6UB01iExAZCEf0/deb7e6070af7d9eaa5fc5db078df576f/2020_Privacy_Notice_FNF.pdf">Privacy Statement</SmartLink></li>
            <li><SmartLink link="https://www.fnf.com/accessibility/">Accessibility</SmartLink></li>
            <li><SmartLink link={privacyOptOutUrl}>Privacy Data Collection Opt Out Request</SmartLink></li>
            <li><SmartLink link="https://fnf.com/CaliforniaPrivacy">California Privacy</SmartLink></li>
            <li><SmartLink link="/document-execution/">Document Execution</SmartLink></li>
          </ul>
        </nav>
      </ResponsiveContainer>
    </div>
  </footer>)
};

export default Footer;
